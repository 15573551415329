<template>
  <div class='yuntai' :id="uuidNum">
    <div class="title_nameR mb10"><div>云台</div></div>
    <div class="yuntaiFlex">
      <div class="yuntaiCont" :class="{'noPTZ':!isShowPTZ}">
        <div :class="['item',item.className]" v-for="item in yuntaiList" :key="item.name" ref="itemYtRef" @mousedown="ptzStart(item.code,'down')" @mouseup="ptzStop(item.code,'up')">
          <!-- <i class="el-icon el-icon-caret-top"></i> -->
        </div>
        <!-- <div class="center1"></div> -->
      </div>
      <div class="yuntaiBtn" :class="{'noPTZ':!isShowPTZ}">
        <el-button-group>
          <el-button type="primary" icon="el-icon-minus" size="mini" @mousedown.native="ptzStart(9,'down')" @mouseup.native="ptzStop(9,'up')"></el-button>
          <el-button type="primary" size="mini">缩放</el-button>
          <el-button type="primary" icon="el-icon-plus" size="mini" @mousedown.native="ptzStart(8,'down')" @mouseup.native="ptzStop(8,'up')"></el-button>
        </el-button-group>
        <el-button-group>
          <el-button type="primary" icon="el-icon-minus" size="mini" @mousedown.native="ptzStart(11,'down')" @mouseup.native="ptzStop(11,'up')"></el-button>
          <el-button type="primary" size="mini">焦点</el-button>
          <el-button type="primary" icon="el-icon-plus" size="mini" @mousedown.native="ptzStart(10,'down')" @mouseup.native="ptzStop(10,'up')"></el-button>
        </el-button-group>
        <!-- <el-button-group>
          <el-button type="primary" icon="el-icon-minus" size="mini" @mousedown.native="ptzControl(17,'down')" @mouseup.native="ptzControl(17,'up')"></el-button>
          <el-button type="primary" size="mini">光圈</el-button>
          <el-button type="primary" icon="el-icon-plus" size="mini" @mousedown.native="ptzControl(16,'down')" @mouseup.native="ptzControl(16,'up')"></el-button>
        </el-button-group> -->
        <el-slider class="speedSlid" v-model="speedNum" :step="1" :min="0" :max="2"></el-slider>
      </div>
    </div>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import {getUUID} from '@/utils/utils'
// var vm;
export default {
  props:{
    urlList: {
      type: Array,
      default: [{
        id:'',
        wsUrl:'',
        isPTZ:'0'
      }],
    },
    isActive: {
      type: Number,
      default: -1,
    },
    isShowPTZ: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      speedNum:1,//云台控制速度
      yuntaiList:[
        {
          name:'上',
          rotate:90,
          className:'item1',
          code:0
        },{
          name:'右上',
          rotate:0,
          className:'item2',
          code:6
        },{
          name:'右',
          rotate:0,
          className:'item3',
          code:3
        },{
          name:'右下',
          rotate:0,
          className:'item4',
          code:7
        },{
          name:'下',
          rotate:0,
          className:'item5',
          code:1
        },{
          name:'左下',
          rotate:0,
          className:'item6',
          code:5
        },{
          name:'左',
          rotate:0,
          className:'item7',
          code:2
        },{
          name:'左上',
          rotate:0,
          className:'item8',
          code:4
        }
      ],

      uuidNum:'',

    }
  },
  created(){
    // vm = this
    this.uuidNum = getUUID()
  },
  activated(){
    // vm = this
  },

  //方法
  methods:{
    //云台控制
    async ptzStart(code,type){
      let that = this
      if (that.isActive < 0) return that.$message.warning('未选中播放器');
      if (!that.isShowPTZ) return false;
      let dataObj = {
        'channelId':that.urlList[that.isActive]?.id,
        'direction':code,
        'speed':that.speedNum
      }
      const res = await apiUrl.ptzStart(qs.stringify(dataObj))
      if(res?.code==200){
        
      }else{
        that.$message.error(res.message)
      }
      
    },
    //云台控制
    async ptzStop(code,type){
      let that = this
      if (that.isActive < 0) return that.$message.warning('未选中播放器');
      if (!that.isShowPTZ) return false;
      let dataObj = {
        'channelId':that.urlList[that.isActive]?.id,
        'direction':code
      }
      const res = await apiUrl.ptzStop(qs.stringify(dataObj))
      if(res?.code==200){
        
      }else{
        that.$message.error(res.message)
      }
      
    },
  }
}
</script>
<style lang='scss'>
.yuntai{
  .yuntaiFlex{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    .yuntaiBtn{
      width: 82px;
      margin-left: 8px;
      .speedSlid{
        width:80%;
        margin:0 auto;
        .el-slider__runway{
          height: 4px;
          background-color: #317f9b;
          .el-slider__bar{
            height: 4px;
            background-color: #317f9b;
          }
          .el-slider__button{
            border: none;
            background-color: #317f9b;
          }
        }
      }
      .el-button-group{
        margin: 8px 0;
        .el-button--mini{
          padding: 2px;
          color: #fff;
          background:none;
          border: none;
          &:first-child,&:last-child{
            border: 1px solid #317f9b;
            border-radius: 4px;
          }
          &:nth-child(2){
            padding: 2px 10px;
            &::after{
              content: "";
              position: absolute;
              border: 1px dashed #163862;
              width: 100%;
              height: 0;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 0;
            }
            span{
              position: relative;
              z-index: 1;
            }
          }
        }
      }
      &.noPTZ{
        .el-button--mini{
          color: #babcbe;
          cursor: auto;
          &:first-child,&:last-child{
            border: 1px solid #204773;
          }
        }
        .speedSlid{
          .el-slider__runway{
            background-color: #224a76;
            .el-slider__bar{
              background-color: #224a76;
            }
            .el-slider__button{
              border: none;
              background-color: #224a76;
            }
          }
        }
      }
    }
    
  }
  .yuntaiCont{
    width: 136px;
    height: 136px;
    border-radius: 50%;
    position: relative;
    background: url('../../assets/images/yt_bg2.png') center no-repeat;
    // margin: 0 5px;
    flex-shrink: 0;
    .item,.center1{
      cursor: pointer;
      position: absolute;
    }
    .item{
      width: 44px;
      height: 33px;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(-50%);
      background: url('../../assets/images/yt_btn_1.png') center no-repeat;
      // pointer-events: none;
      &:active{
        background: url('../../assets/images/yt_btn_hover.png') center no-repeat;
      }
      .el-icon{
        font-size: 20px;
      }
      &.item2{
        top: 24%;
        left: 60%;
        transform: translate(-10%,-35%) rotate(45deg);
      }
      &.item3{
        top: 50%;
        left: 82%;
        transform: translate(-50%,-50%) rotate(90deg);
      }
      &.item4{
        top: 69%;
        left: 59%;
        transform: translate(-10%,-35%) rotate(135deg);
      }
      &.item5{
        bottom: 8px;
        top: auto;
        left: 50%;
        transform: translate(-50%,0) rotate(180deg);
      }
      &.item6{
        top: 63%;
        left: 23%;
        transform: translate(-35%,-10%) rotate(-135deg);
      }
      &.item7{
        top: 50%;
        left: 3px;
        transform: translate(0,-50%) rotate(-90deg);
      }
      &.item8{
        top: 21%;
        left: 22%;
        transform: translate(-32%,-25%) rotate(-45deg);
      }
    }
    &.noPTZ{
      background: url('../../assets/images/yt_bg.png') center no-repeat;
      .item{
        background: url('../../assets/images/yt_btn.png') center no-repeat;
      }
    }
  }
}
</style>
