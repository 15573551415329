<template>
  <div class='monitorComp' :id="uuidNum">
    <div class="list" :style="{ gridTemplateColumns: 'repeat(' + gridTColumns + ' , 1fr)', height:height }">
      <div v-for="(item, index) in gridinfoList.arr" :key="item.indexNum" ref="girdItemRef" :class="setClass(item, index)" @click="vedioToggle(index)"
        v-loading="loadData[index].isLoading"
        element-loading-text="连接中..."
        element-loading-spinner="el-icon-loading">
        <jessibuca-pro :url="item.webrtcUrl" @reload="reloadVideo" @close="closeVideo($event,index)"></jessibuca-pro>
        <div class="tips error">{{ loadData[index].tips }}</div>
      </div>
    </div>
    <div class="bottomDiv flex">
      <div style="display: inline-flex; align-items: center;">
        <div class="btnBt el-icon-circle-close mr15" title="关闭所有摄像头" @click="closeAll()"></div>
        <div class="btnPoll">
          <div class="btn-time"><i class="el-icon-time"></i></div>
          <el-input-number class="mr15" :precision='0' controls-position="right" v-model="pollInput" :min="5" :max="30" placeholder="轮巡间隔(秒)"></el-input-number>
          <div class="btnBt mr10 icon icon-play" :class="{'disBtn':intervalTime}" title="开启轮巡" @click="startRound()"></div>
          <div class="btnBt icon icon-stop" :class="{'disBtn':intervalTime==null}" title="停止轮巡" @click="stopRound()"></div>
        </div>
      </div>
      <div>
        <div class="tempItem">
          <div :class="['btnBt item',{'active':item.value == setUpInfo.templateInfo}]" v-for="item in optionsInfo" :key="item.value" @click="changeOption(item.value)">
            <img :src="setTempImg(item.icon)" alt="" v-if="item.value != setUpInfo.templateInfo">
            <img :src="setTempImg(item.iconActive)" alt="" v-else>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//视频播放插件
import jessibucaPro from '@/components/jessibuca-pro'
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
import {formatTime,getUUID} from '@/utils/utils'
import {initWebSocket,websocketsend,websocketclose} from '@/utils/websocketUdp'
// let vm;
export default {
  props:{
    height: {
      type: String,
      default: 'calc(100vh - 110px)',
    },
    //是否需要判断临时存储
    isSession: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive :-1,

      urlList:[],//所有地址数组数据
      // 视频预览设置
      setUpInfo :{
        templateInfo: 0, // 模板
      },
      optionsInfo:[
        { value: 4, label: "1",icon:'icon12_1',iconActive:'icon12_1_1' },
        { value: 0, label: "4",icon:'icon12_2',iconActive:'icon12_2_1' },
        { value: 1, label: "9",icon:'icon12_3',iconActive:'icon12_3_1' },
        { value: 5, label: "1+5",icon:'icon12_4',iconActive:'icon12_4_1' },
        { value: 2, label: "1+7",icon:'icon12_5',iconActive:'icon12_5_1' },
        { value: 6, label: "16",icon:'icon12_6',iconActive:'icon12_6_1' },
      ],
      gridTColumns:2,//视频框列数
      gridinfoList:{
        arr:[]
      },// grid 长度
      girdItemRefList:[],// GridItem 所有坐标

      uuidNum:'',
      isLoading:false,
      loadData:[],

      pollInput:20,//轮巡间隔时间（秒）
      curr:1,//轮巡时页数
      intervalTime:null,//轮巡定时器

      rc:null,//采集音频数据

      demoData:[],

      audioPlayList:[],//获取到的音频流数据

      websocketIp:'',//websocketIp
      websocketPort:'',//websocketPort
    }
  },
  components:{
    jessibucaPro,
  },
  created(){
    // vm = this;
    this.uuidNum = getUUID()
    let arr = []
    for(var i=0;i<16;i++){
      arr.push({
        'isLoading':false,
        'tips':''
      })
    }
    this.loadData = arr

    // this.sysParamGetMap()
  },
  watch:{
    isActive(newVal){
      // console.log('watch-isActive',newVal)
      this.$emit('activeEmit',newVal)
    },
    urlList:{
      handler:function(newVal){
        // console.log('watch-urlList',newVal)
        this.$emit('urlListEmit',newVal)
      },immediate: true,deep: true
    },
    '$store.state.wsMsg':function(newVal){
      this.getSkMsg(newVal)
    },
    '$store.state.wsMsgUdp':function(newVal){
      this.getSkMsgUdp(newVal)
    }
  },
  mounted(){
    this.gridTColumns = 2
    this.gridinfoList.arr = this.cycleGridInfoList(0,4);
    let _this = this

    this.$nextTick(() => {
      _this.setGirdItemDOMRect(4);

      // initWebSocket()
    });
  },
  async activated(){
    for(var i in this.urlList){
      if(this.urlList[i].webrtcUrl){
        let webrtcUrl = await this.againGetUrl(this.urlList[i].id,i)
        if(webrtcUrl){
          this.urlList[i].webrtcUrl = webrtcUrl
          this.gridinfoList.arr[i].webrtcUrl = webrtcUrl
        }
      }
    }
  },
  deactivated(){
    if(this.intervalTime){
      this.stopRound()
      this.curr = 1
    }

    for(var i in this.gridinfoList.arr){
      this.gridinfoList.arr[i].webrtcUrl = ''
    }
    this.stopAudio()
  },

  //方法
  methods:{
    //切换对讲
    async toggleRecord(index,item){
      let that = this
      if(this.gridinfoList.arr[index].isRecord == false){
        if(this.rc) return this.$message.warning('已有设备正在对讲...')
        this.getEnumerateDevices(index)
      }else{
        this.stopAudio(index)
      }
    },
    //获取本地采集音频设备
    getEnumerateDevices(index){
      let that = this
      //查询是否有音频设备
      navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {
          console.log('devices',devices);
          var hasAudioDevice = false;
          devices.forEach(function(device) {
            if (device.kind === 'audioinput') {
              hasAudioDevice = true;
            }
          });
          // 判断是否有音频设备
          if (hasAudioDevice) {
            console.log("浏览器具有音频设备");
            that.openRtpServer(index)
          } else {
            that.$message.warning('请插入音频设备！')
            console.log("浏览器没有音频设备");
          }
        })
        .catch(function(err) {
          console.log(err.name + ": " + err.message);
        });
    },
    //获取系统参数
		async sysParamGetMap () {
			let dataObj = {
				'paramDomain': 'PARAM_DOMAIN_EQUIPMENT_INTERCOM_STREAM_SERVER'
			}
			const res = await apiUrl.sysParamGetMap(qs.stringify(dataObj))
			if(res?.code==200){
				this.websocketIp = res.data.PARAM_CODE_EQUIPMENT_INTERCOM_STREAM_SERVER_IP
				this.websocketPort = res.data.PARAM_CODE_EQUIPMENT_INTERCOM_STREAM_SERVER_WSS_PORT
			}else{
				this.$message.error(res.message)
			}
		},
    //获取udp连接的ip和端口
    async openRtpServer(index){
      let dataObj = {
        'id': this.urlList[index].id,
        // 'id': '123',
        'enableTcp': 0,//0:udp,1:tcp
        'onlyAudio':1,//0:音视频,1:音频,2:视频
      }
      const res = await apiUrl.openRtpServer(qs.stringify(dataObj))
      if(res?.code == 200){
        if(this.websocketIp&&this.websocketPort){
          initWebSocket(`${this.websocketIp}:${this.websocketPort}/websocket/intercom/udp/${res.data.talkServer}/${res.data.talkPort}`,index.toString());
        }
        // this.startTalk(index,res.data.talkServer,res.data.talkPort)
      }else{
        this.$message.error(res.message)
      }
    },
    //开启摄像头对讲
    async startTalk(index){
      const res = await apiUrl.startTalk(this.urlList[index].id)
      if(res?.code == 200){
        console.log('开启对讲');
        // initWebSocket(`192.168.66.28:8889`);
        // initWebSocket(`192.168.66.26:7090/websocket/intercom/udp/${talkServer}/${talkPort}`);
        // initWebSocket(`192.168.66.26:7090/websocket/intercom/udp/192.168.66.189/10000`);
        // this.startAudio(index)
        // this.createVideo(index,'http://106.52.117.137:8083/index/api/webrtc?app=rtp&stream=34020000011320000001_1&type=play')
      }else{
        this.$message.error(res.message)
        this.stopAudio(index)
      }
    },
    //关闭摄像头对讲
    async stopTalk(index){
      const res = await apiUrl.stopTalk(this.urlList[index].id)
      if(res?.code==200){
        console.log('关闭对讲');
        // this.$message.success('对讲已关闭')
      }else{
        // this.$message.error(res.message)
      }
    },
    //开始讲话
    async startAudio(index){
      this.demoData = []
      let that = this;
      this.$nextTick(function () {
        //可以用下面的代码来边讲话边听
        const audio = new Audio()
        audio.autoplay = true
        //{ sampleRate: 8000, channels: 1, bitDepth: 8 }
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
          // setTimeout(()=>{
          //   that.startTalk(index)
          // },5000)
          that.rc = stream
        // that.rc.start().then(async (stream) => {
          that.gridinfoList.arr[index].isRecord = true
          //打开监控的声音
          that.$refs.videoItemRef[index].muted = false
          that.gridinfoList.arr[index].isMuted = false

          // audio.srcObject = stream
          
          that.audioContext = new AudioContext({
            sampleRate: 8000,channels: 1
          });
          const mediaStreamSource = that.audioContext.createMediaStreamSource(stream);

          // 注册并加载音频工作线程
          // that.audioContext.audioWorklet.addModule('@/assets/js/audioworklet.js')
          // .then(() => {
          //   // 创建 AudioWorkletNode
          //   const workletNode = new AudioWorkletNode(that.audioContext, 'my-audio-worklet-processor');

          //   // 连接音频输入和输出
          //   mediaStreamSource.connect(workletNode);
          //   workletNode.connect(that.audioContext.destination);

          //   // 音频处理逻辑已在 audioworklet.js 中定义
          // })
          // .catch((error) => {
          //   console.error('加载音频工作线程失败：', error);
          // });

          // 创建 ScriptProcessorNode 来处理音频数据
          const bufferSize = 1024; // 根据需要选择缓冲区大小
          const scriptNode = that.audioContext.createScriptProcessor(bufferSize, 1, 1);
          let pakeNum = 0;//包序列号
          // let sequenceNumber = 0;//序列号
          // let timestamp = 0;//开始截取时间戳
          // const ssrc = Math.floor(Math.random() * 0xFFFFFFFF);//同步源标识符, 32位的无符号整数

          // // 设置输入采样率和目标采样率
          // const inputSampleRate = that.audioContext.sampleRate;
          // const targetSampleRate = 8000;
          // // 计算采样率比例
          // const resampleRatio = targetSampleRate / inputSampleRate;
          // // 创建一个数组用于存储上一次未处理的样本
          // let lastUnprocessedSamples = new Float32Array(0);
          // 当音频处理事件发生时
          scriptNode.onaudioprocess = (event) => {
            const inputBuffer = event.inputBuffer;
            const inputData = inputBuffer.getChannelData(0);

            // 将音频数据编码为 RTP 协议头的 16 位二进制数据
            let rtpData = new Int16Array(inputData.length);
            // let rtpData = g711.alawFromPCM(inputData);
            
            for (let i = 0; i < inputData.length; i++) {
              rtpData[i] = inputData[i] * 32767; // 缩放到 16 位范围
              // const scaledValue = Math.max(-128, Math.min(127, Math.round(inputData[i] * 128)));
              // rtpData[i] = scaledValue + 128; // 转换为 8 位数据范围
            }

            //原数据的二进制数据
            const rtpBinary = rtpData.buffer;
            //发送原数据到websocket服务
            websocketsend(rtpBinary);
            // that.demoData.push(rtpBinary);

            // 创建 RTP 协议头
            // const version = 2; // RTP 版本
            // const padding = 0; // 填充位
            // const extension = 0; // 扩展位
            // const csrcCount = 0; // CSRC 计数
            // const marker = 0; // 标记位
            // const payloadType = 8; // 负载类型 8：PCMA;96：h264视频编码
            // const sampleRate = 8000; // 音频采样率
            
            // // 根据需要的时间位置和采样率计算时间戳
            // timestamp += (bufferSize / sampleRate) * 90000; // bufferSize 是 RTP 包的大小
            // const rtpHeader = new Uint8Array(12); // RTP 协议头为12字节
            // const dataView = new DataView(rtpHeader.buffer);
            // dataView.setUint8(0, (version << 6) | (padding << 5) | (extension << 4) | csrcCount);//<<左移操作符
            // dataView.setUint8(1, (marker << 7) | payloadType & 0x7F);
            // // dataView.setUint8(1, (marker << 7) | payloadType);
            // dataView.setUint16(2, sequenceNumber);
            // dataView.setUint32(4, timestamp);
            // dataView.setUint32(8, ssrc);
            // // 将 RTP 协议头和 rtpBinary 合并为一个数据包
            // const rtpPacket = new Uint8Array(rtpHeader.length + rtpBinary.byteLength);
            // rtpPacket.set(rtpHeader);
            // rtpPacket.set(new Uint8Array(rtpBinary), rtpHeader.length);
            // sequenceNumber = (sequenceNumber + 1) & 0xFFFF;
            pakeNum += 1 ;
            if(pakeNum == 10){
              that.startTalk(index)
            }

            // // 发送 RTP 数据到 WebSocket 服务器
            // websocketsend(rtpPacket);
            // 将音频数据添加到数组以便后续保存为 .bin 文件
            // that.demoData.push(rtpPacket);
          };
          mediaStreamSource.connect(scriptNode);
          scriptNode.connect(that.audioContext.destination);
        })
        .catch(error => alert(error));
      })
    },
    //结束讲话
    stopAudio(index){
      const audioTrack = this.rc?.getAudioTracks()[0];
      if(audioTrack){
        audioTrack.stop();
      }
      // this.rc.pause();
      // this.rc.clear();
      // console.log('rc',this.rc);
      if(this.rc){
        this.rc = null
        this.audioContext.close()
        this.audioContext = null
        
        if(index){
          this.gridinfoList.arr[index].isRecord = false
          //关闭监控的声音
          this.$refs.videoItemRef[index].muted = true
          this.gridinfoList.arr[index].isMuted = true
          this.stopTalk(index)
        }else{
          console.log('noIndex',this.gridinfoList.arr);
          for(var i in this.gridinfoList.arr){
            if(this.gridinfoList.arr[i].isRecord==true){
              this.$refs.videoItemRef[i].muted = true
              this.gridinfoList.arr[i].isMuted = true
              this.stopTalk(i)
            }
            this.gridinfoList.arr[i].isRecord = false
          }
        }
        websocketclose()
      }
      // if(this.audioPlayList.length>0 && this.audioPlayList[index]){
      //   this.destoryAudio(this.audioPlayList[index])
      // }

      //临时下载.bin测试采集的数据
      // const audioBlob = new Blob(this.demoData, { type: 'audio/wav' });
      // const audioUrl = URL.createObjectURL(audioBlob);
      // const downloadLink = document.createElement('a');
      // downloadLink.href = audioUrl;
      // downloadLink.download = 'audio.bin';
      // downloadLink.click();
      // URL.revokeObjectURL(audioUrl);
    },
    //udpwebsocket接收
    getSkMsgUdp(res){
      // console.log(res);
      //自己定义的websocket连接成功返回
      if(res.type == 'wsSuccess'){
        if(!this.rc){
          this.startAudio(res.data)
        }
      }
      
      // this.demoData.push(res);
    },
    //websocket接收
    async getSkMsg(res){
      let that = this
      //判断是设备在线返回
      if(res?.type == 'channelStatus'){
        if(that.urlList.length>0){
          for(var i in that.urlList){
            if(res.data.channelData.channelId == that.urlList[i].id){
              that.urlList[i].equStatus = res.data.channelData.channelStatus
              for(var x in that.gridinfoList.arr){
                if(that.urlList[i].webrtcUrl == that.gridinfoList.arr[x].webrtcUrl){
                  that.gridinfoList.arr[x].webrtcUrl = ''
                  //判断是上线
                  if(res.data.channelData.channelStatus== 1 ){
                    let webrtcUrl = await that.againGetUrl(that.urlList[i].id,i)
                    if(webrtcUrl){
                      that.urlList[i].webrtcUrl = webrtcUrl
                      that.gridinfoList.arr[x].webrtcUrl = webrtcUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    //开启轮巡
    startRound(){
      let that = this
      if(this.intervalTime) return false
      let newArr = this.urlList.filter(item => Boolean(item.id));
      if(newArr.length == 0) return this.$message.warning('暂无监控在查看中')
      // console.log('轮巡的列表',newArr);
      if(newArr.length > 0 && newArr.length<=this.gridinfoList.arr.length) return this.$message.warning('监控数量未超出视频框数量，无需轮巡')
      that.intervalTime = setInterval(() => {
        for(var i in that.gridinfoList.arr){
          if(that.gridinfoList.arr[i].webrtcUrl){
            let videoIds = that.vtp.getSession('videoIds')
            if(videoIds && that.isSession){
              videoIds.splice(i,1)
              if(videoIds.length > 0){
                that.vtp.setSession('videoIds',videoIds)
              }else{
                sessionStorage.removeItem('videoIds')
              }
            }
          }
          that.gridinfoList.arr[i].webrtcUrl = '';
          that.loadData[i].isLoading = false
        }
        
        let index = that.gridinfoList.arr.length
        let pageTotal = Math.ceil(newArr.length / index);//向上取整
        //判断是否最后一页就返回第一页，否则继续下一页
        if(that.curr == pageTotal){
          that.curr = 1
        }else{
          that.curr+=1
        }
        let arr = newArr.slice((index*that.curr)-index, (index*that.curr));
        for(var i in arr){
          // console.log(i,arr[i].id);
          that.gridinfoList.arr[i].webrtcUrl = arr[i].webrtcUrl
          // that.gridinfoList.arr[i].isClose = true
          // that.createVideo(parseInt(i))
        }
        
      }, that.pollInput * 1000);
    },
    //停止轮巡
    stopRound(){
      clearInterval(this.intervalTime)
      // this.curr = 1
      this.intervalTime = null
    },
    //关闭所有
    closeAll(){
      if(this.urlList.length>0){
        const hasWebrtcUrl = this.urlList.some(item => item.webrtcUrl);
        //判断是否有视频地址（是否有视频在播放）
        if(!hasWebrtcUrl){//如果没有直接return掉
          return
        }
        this.$confirm('是否关闭所有摄像头','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        .then(() => {
          for(var i in this.urlList){
            if(this.urlList[i].webrtcUrl){
              // console.log(i);
              this.closeItemVideo(i)
            }
          }
          this.stopRound()
          this.curr = 1
        })
        .catch(() => {})
      
        
      }
    },
    //重新获取地址
    async againGetUrl(id,i){
      let webrtcUrl = await this.deviceMediaLive(id,i)
      return webrtcUrl
    },
    //根据摄像头id获取播放流地址数据
    async deviceMediaLive (id,i) {
      this.loadData[i].isLoading = true
      let dataObj = {
        'channelId':id,
      }
      const res = await apiUrl.deviceMediaLive(dataObj)
      this.loadData[i].isLoading = false
      if(res?.code == 200){
        // return location.protocol == 'https:'? res.data?.webrtc : res.data?.webrtcHttp
        return res.data?.hls
      }else{
        this.loadData[i].tips = res.message
        let timeOut = setTimeout(() => {
          this.loadData[i].tips = ''
          clearTimeout(timeOut)
        }, 3000);
        // this.$message.error(res.message)
        return ''
      }
    },
    async init (arr){
      let that = this
      that.xiaohuiVideo()
      // console.log('init-后');
      for(var i in arr){
        that.gridinfoList.arr[i].webrtcUrl = arr[i]
        that.isActive = parseInt(i)
      }
    },
    //销毁实例
    async xiaohuiVideo(){
      var that = this
      for(let y=0;y< that.gridinfoList.arr.length;y++){
        if(that.gridinfoList.arr[y]){
          that.gridinfoList.arr[y].webrtcUrl = ''
        }
      }
    },
    //双击过来添加当前播放
    async initCurr(i){
      let that = this
      // console.log(that.gridinfoList.arr,that.urlList);
      if(that.gridinfoList.arr[i] && that.urlList[i]){
        that.gridinfoList.arr[i].webrtcUrl = that.urlList[i].webrtcUrl
        // that.gridinfoList.arr[i].isClose = true
        that.isActive = parseInt(i)
        // that.createVideo(parseInt(i))
      }
    },
    //组件返回重新加载视频
    async reloadVideo(val){
      for(var i in this.urlList){
        if(this.urlList[i].webrtcUrl == val){
          for(var x in this.gridinfoList.arr){
            if(this.urlList[i].webrtcUrl == this.gridinfoList.arr[x].webrtcUrl){
              let webrtcUrl = await this.againGetUrl(this.urlList[i].id,i)
              this.gridinfoList.arr[x].webrtcUrl = ''
              if(webrtcUrl){
                this.urlList[i].webrtcUrl = webrtcUrl
                this.gridinfoList.arr[x].webrtcUrl = webrtcUrl
              }
            }
          }
        }
      }
    },
    //组件返回关闭视频
    closeVideo(val,index){
      this.closeItemVideo(index)
    },
    //关闭当前视频
    async closeItemVideo (index){
      let that = this
      if(that.gridinfoList.arr[index]){
        that.gridinfoList.arr[index].webrtcUrl = '';
        that.gridinfoList.arr[index].isMuted = true
        that.gridinfoList.arr[index].isClose = false
        that.gridinfoList.arr[index].isRecord = false
      }
      
      this.stopAudio(index)

      that.urlList[index].id = ''
      that.urlList[index].equBaseId = ''
      that.urlList[index].webrtcUrl = ''
      that.urlList[index].isPtz = 0
      that.urlList[index].is_Intercom  = 0
      that.urlList[index].equStatus = 0
      
      let videoIds = that.vtp.getSession('videoIds')
      if(videoIds && that.isSession){
        videoIds.splice(index,1)
        if(videoIds.length > 0){
          that.vtp.setSession('videoIds',videoIds)
        }else{
          sessionStorage.removeItem('videoIds')
        }
        
      }

    },
    
    //设置视频模板选择
    setTempImg (icon) {
      return require(`@/assets/images/icon/${icon}.png`)
    },

    /**
   * 循环 返回 模板
   * @param {String | Number} name 选择器选中的value
   * @param {Number} length grid的长度
   */
    cycleGridInfoList (name, length) {
      let that = this
      let gridinfoArr = [];
      for (let index = 0; index < length; index++) {
        var objItem = { 
          indexNum: index, 
          className: `item-${name}-${index}`,
          webrtcUrl:'',
          isRecord:false,
        }
        if(that.gridinfoList.arr[index]?.webrtcUrl){
          objItem.webrtcUrl= that.gridinfoList.arr[index].webrtcUrl
          // objItem.isMuted = that.gridinfoList.arr[index].isMuted
        }
        
        gridinfoArr.push(objItem);
      }
      return gridinfoArr;
    },
    // 赋值 girtItem 坐标
    setGirdItemDOMRect(length) {
      let that = this
      let GirdItemDOMRect = [];
      for (let index = 0; index < length; index++) {
        GirdItemDOMRect.push(
          that.$refs.girdItemRef[index]?.getBoundingClientRect()
        );
      }
      that.girdItemRefList = GirdItemDOMRect;
    },
    // 模板 选择器 选中
    async changeOption (val){
      let that = this
      if(val==4&&that.isActive==-1){
        that.$message.warning('请选择需要单个查看的监控')
        return
      }
      that.stopAudio()
      await that.xiaohuiVideo()
      //切换模板时停止
      var isInterval = false;//判断是否在轮巡
      if(this.intervalTime){
        isInterval = true
        this.stopRound()
        this.curr = 1
      }
      // console.log('changeOption-模板切换后');
      that.setUpInfo.templateInfo = val
      if (val == 0) {
        // 模板名称：4
        that.gridTColumns = 2;
        that.gridinfoList.arr = that.cycleGridInfoList(0, 4);
        that.$nextTick(()=>{
          that.setGirdItemDOMRect(4);
        })
      }
      if (val == 1) {
        // 模板名称：9
        that.gridTColumns = 3;
        that.gridinfoList.arr = that.cycleGridInfoList(1, 9);
        that.$nextTick(() => {
          that.setGirdItemDOMRect(9);
        });
      }
      if (val == 2) {
        // 模板名称：1+7
        that.gridTColumns = 4;
        that.gridinfoList.arr = that.cycleGridInfoList(2, 8);
        that.$nextTick(() => {
          that.setGirdItemDOMRect(8);
        });
      }
      if (val == 4) {
        // 模板名称：1
        that.gridTColumns = 1;
        that.gridinfoList.arr = that.cycleGridInfoList(4, 1);
        that.$nextTick(() => {
          that.setGirdItemDOMRect(1);
        });
      }
      if (val == 5) {
        // 模板名称：1+5
        that.gridTColumns = 3;
        that.gridinfoList.arr = that.cycleGridInfoList(5, 6);
        that.$nextTick(() => {
          that.setGirdItemDOMRect(6);
        });
      }
      if (val == 6) {
        // 模板名称：16
        that.gridTColumns = 4;
        that.gridinfoList.arr = that.cycleGridInfoList(6, 16);
        that.$nextTick(() => {
          that.setGirdItemDOMRect(16);
        });
      }
      // console.log('ccc',that.urlList);

      for(var i in that.urlList){
        for(var x in that.gridinfoList.arr){
          if(i == x){
            if(val == 4 && that.isActive>=0){
              let webrtcUrl = that.urlList[that.isActive].webrtcUrl? await that.againGetUrl(that.urlList[that.isActive].id,that.isActive):''
              that.gridinfoList.arr[x].webrtcUrl = webrtcUrl
            }
            if(val != 4){
              let webrtcUrl = that.urlList[x].webrtcUrl? await that.againGetUrl(that.urlList[x].id,x):''
              that.gridinfoList.arr[x].webrtcUrl = webrtcUrl
            }
          }
        }
      }
      if(isInterval==true){
        this.startRound()
      }
    },
    // 是否被选中
    setClass (item, index) {
      let that = this
      if (that.isActive == index) {
        return `item ${item.className} active`
      } else {
        return `item ${item.className}`
      }
    },
    //点击选中框
    vedioToggle (type){
      let that = this
      if(that.isActive == type){
        that.isActive = -1
      }else {
        that.isActive = type
      }
    },

  }
}
</script>
<style lang='scss'>
.monitorComp{
  .list{
    display: grid;
    background: #fff;
    // height: calc(100vh - 110px);
    .item{
      flex-shrink: 0;
      box-sizing: border-box;
      background:url('../../assets/images/video.png') center no-repeat;
      background-size: 15%;
      border: 1px solid #ddd;
      position: relative;
      overflow: hidden;
      .tips{
        position: absolute;
        left: 0;
        right: 0;
        top: 20%;
        text-align: center;
        z-index: 0;
      }
      .closeIcon{
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 18px;
        color: #000;
        cursor: pointer;
        z-index: 10;
        i{
          background: #fff;
          border-radius: 50%;
        }
      }
      .crtlDiv{
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-size: 18px;
        z-index: 1;
        display: inline-flex;
        .mutedBtn{
          background-color: #fff;
          cursor: pointer;
          border-radius: 50%;
          padding: 2px 5px;
          i{
            color: #000;
            border-radius: 50%;
          }
        }
      }
      
      .videoItem{
        width: 100%;
        height: 100%;
        object-fit: unset;
        position: relative;
        z-index: 1;
      }
      .audioItem{
        position: relative;
        z-index: -1;
      }
      &.active{
        border: 1px solid #409EFF;
        box-shadow: 0 0px 3px 3px rgba(#409EFF,.4);
        z-index: 2;
      }
      &.item-5-0 {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }

      &.item-2-0 {
        grid-column: 1 / 4;
        grid-row: 1 / 4;
      }
    }
  }
  .bottomDiv{
    padding: 5px;
    position: relative;
    z-index: 1;
    .mr15{
      margin-right: 15px;
    }
    .btnBt{
      width: 42px;
      height: 42px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: url('../../assets/images/btn_rbg.png') center no-repeat;
      font-size: 25px;
      cursor: pointer;
      &.disBtn{
        color: #c7c7c7;
        &:hover{
          color: #c7c7c7;
        }
      }
      &:hover{
        color: #5bd2ef;
      }
    }
    .btnPoll{
      display: inline-flex;
      align-items: center;
      .el-input-number{
        width: 95px;
        .el-input-number__decrease,.el-input-number__increase{
          background: none;
          color: #fff;
          border-bottom: none;
          border-left: 1px solid #144263;
        }
        .el-input__inner{
          background:none;
          color: #fff;
          border: 1px solid #144263;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          
        }
      }
      .btn-time{
        border: 1px solid #144263;
        height: 40px;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        padding: 0 10px;
        i{
          font-size: 25px;
          color: #fff;
        }
      }
    }
    .tempItem{
      display: flex;
      // box-shadow: 0 3px 3px 2px rgba(0,0,0,.2);
      overflow: hidden;
      .item{
        margin-left: 10px;
        &.active,&:hover{
          
        }
      }
    }
  }
}
</style>
