<template>
  <div>
  <div class='deviceComp'>
    <div class="searchBD pt10 mr10 ml10">
      <el-input type="text" size="small" v-model="filterText" placeholder="输入关键词" suffix-icon="el-icon-search"/>
      <i class="toggleBtn el-icon-finished" v-if="isAll==true" @click="toggleAll()" title="只看在线"></i>
      <i class="toggleBtn el-icon-s-operation" v-else @click="toggleAll()" title="查看全部"></i>
    </div>
    <div class="list" :style="{height:listHeight}">
    <el-tree
      ref="treeRef"
      class="filter-tree"
      :data="treeData"
      :props="defaultProps"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :filter-node-method="filterNode"
      highlight-current
      @node-click="treeClick"
      empty-text="暂无数据">
        <span :class="{'isDisabled':data.equStatus == 0}" slot-scope="{ node, data }">
          <draggable
            :list="[data]" v-bind="dragOptions" :delay="30" :move="onMove" @end="nodeDragEnd($event,node)" forceFallback="true">
            <transition-group>
              <div v-for="(item,index) in [data]" :key="item.id">
                <!-- <template v-if="item.nodeType=='device'">
                <img style="width:14px;height:14px;" src="@/assets/images/icon/icon15.png" alt="">
                </template> -->
                <i class="el-icon-monitor" v-if="item.nodeType=='device'"></i>
                <i class="el-icon-video-camera" v-if="item.nodeType=='channel'"></i>
                <span v-if="isMove" class="ml5" :title="node.label">{{ node.label }}</span>
              </div>
            </transition-group>
            <span v-if="isMove==false" slot="footer" class="ml5" :title="node.label">{{ node.label }}</span>
          </draggable>
          <!-- <template v-if="data.nodeType=='group'">
          <img style="width:14px;height:14px;" src="@/assets/images/icon/icon15.png" alt="">
          </template>
          <i class="el-icon-video-camera" v-if="data.nodeType=='device'"></i>
          <span class="ml5">{{ node.label }}</span> -->
        </span>
    </el-tree>
    </div>
    <div class="treeEditBtn" v-if="isShowBtnDiv">
      <div :class="['item',{'display':isShowBtn==false}]" @click="openDio('look')"><i class="el-icon el-icon-video-play"></i><div>查看</div></div>
      <div :class="['item',{'display':isShowBtn==false}]" @click="openDio('playBack')"><span class="icon icon-huifang"></span><div>回放</div></div>
    </div>
  </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { treeDataTranslate } from '@/utils/utils'
var vm;
export default {
  props:{
    isShowBtnDiv: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    nodeDragStart: {
      type: Function,
      default: Function,
      required: false,
    },
    nodeDragEnd: {
      type: Function,
      default: Function,
      required: false,
    },
    listHeight: {
      type: String,
      default: 'calc(100vh - 423px)',
      required: false,
    },
    isAllowDBL: {
      type: Boolean,
      default: false,
      required: false,
    },
    isFirstCurr: {
      type: Boolean,
      default: false,
      required: false,
    },
    isMove: {
      type: Boolean,
      default: true,
      required: false,
    },
    treeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isAll:true,//是否查看全部
      filterText :'',//筛选词
      //树形数据
      AlltreeData:[],
      treeData :[],
      //树形节点属性设置
      defaultProps :{
        children: 'children',
        label: 'name',
        disabled:'disabled',
      },
      currtreeData :{},//当前树选中节点
      
      isShowBtn :false,//是否显示下方按钮组

      dragOptions: { 
        animation: 500,
        sort: false,
        group: { name: 'field', pull: 'clone', put: false },
      },
    }
  },
  components:{
    draggable,
  },
  created(){
    vm = this
  },
  // activated(){
  //   vm = this
  //   vm.deviceMediaList()
  // },
  watch:{
    filterText(val){
      vm.$refs.treeRef.filter(val)
    },
    '$store.state.wsMsg':function(newVal){
      vm.getSkMsg(newVal)
    },
    'AlltreeData':{
      handler:function(newVal){
        if(this.isAll){
          this.treeData = this.AlltreeData
        }else{
          this.treeData = this.filterTree(this.AlltreeData)
        }
      },immediate: true,deep: true
    },
    '$store.state.homeId':function(newVal){
      if(newVal!=='0'){
        vm.$nextTick(()=>{
          vm.deviceMediaList()
        })
      }
    },
  },
  mounted(){
    vm.deviceMediaList()
  },

  //方法
  methods:{
    //切换筛选
    toggleAll(){
      this.isAll = !this.isAll
      if(this.isAll){
        this.treeData = this.AlltreeData
      }else{
        this.treeData = this.filterTree(this.AlltreeData)
      }
    },
    onMove(e) {
      return true
    },
    //websocket接收
    getSkMsg(res){
      //判断是设备在线返回
      if(res?.type == 'channelStatus'){
        vm.changeUserStatus(res.data.channelData.channelId,res.data.channelData.channelStatus,vm.AlltreeData)
      }
    },
    //遍历查询修改
    changeUserStatus (id,type,data){
      for(var i in data){
        if(data[i].nodeType == 'channel'){
          if(id == data[i].id){
            data[i].equStatus = type
            return
          }
        }
        if(data[i].children?.length>0){
          vm.changeUserStatus(id,type,data[i].children)
        }
      }
    },

    //单击/双击节点
    treeClick (data,node){
      this.numTime++
      setTimeout(function() {
        if (vm.numTime === 1) {// todo 单击的逻辑
          if(data.nodeType == 'channel'){
            vm.isShowBtn = true
          }else{
            vm.isShowBtn = false
          }
          vm.currtreeData = data
          vm.$emit('treeClick',data)
        }
        if (vm.numTime === 2) {// todo 双击的逻辑
          if(data.nodeType=='channel'){
            vm.currtreeData = data
            if(vm.isAllowDBL){
              vm.$emit('treeDblClick',data)
            }else{
              if(data.equStatus==1){
                vm.openDio('look')
              }else{
                vm.$message.error('当前设备不在线')
              }
              
            }
          }else if(data.nodeType=='device'){
            if(vm.isAllowDBL && data.children.length>0){
              vm.$emit('treeDblClickDBL',data.children)
            }
          }
        }
        vm.numTime = 0
      }, 300)
      
    },
    // 判断是否能被拖拽
    allowDrag(draggingNode){
      return draggingNode.childNodes.length == 0;
    },
    // 拖拽时判定目标节点能否被放置
    allowDrop(draggingNode, dropNode, type){
      return false;
    },
    //双击
    treeDbClick (data){
      // console.log(data);
      // if(data.nodeType!='device'){
      //   return false
      // }
      if(data.nodeType=='channel'){
        vm.currtreeData = data
        if(vm.isAllowDBL){
          vm.$emit('treeDblClick',data)
        }else{
          if(data.equStatus==1){
            vm.openDio('look')
          }else{
            vm.$message.error('当前设备不在线')
          }
          
        }
      }else if(data.nodeType=='device'){
        if(vm.isAllowDBL && data.children.length>0){
          vm.$emit('treeDblClickDBL',data.children)
        }
      }
    },

    //点击按钮组
    openDio (type){
      if(vm.isShowBtn){//判断按钮是否不置灰状态
        if(type == 'playBack'){
          vm.$router.push({
            path:'/record-surveillance',
            query:{
              id:vm.currtreeData.id
            }
          })
          return
        }
        let infoObj = {
          'type':type,//按的类型，文字、视频、回放等
          'data':vm.currtreeData
        }
        vm.backDataToParent(infoObj)
      }
      
    },
    //把数据返回父组件
    backDataToParent (val) {
      vm.$emit('backDataToParent',val)
    },
    //过滤查找所有在线的设备
    filterTree (tree, arr = []) {
      if (!tree.length) return []
      for (let item of tree) {
        if (item.nodeType == 'channel'&& item.equStatus==0) continue
        let node = {...item, children: []}
        arr.push(node)
        if (item.children && item.children.length) {
          this.filterTree(item.children, node.children)
        }
      }
      return arr
    },
    //获取设备与通道树形数据
    async deviceMediaList (){
      let dataObj = {
        'areaId':vm.$store.state.csType,
        'homeId':vm.$store.state.homeId,
      }
      const res = await apiUrl.deviceMediaList(qs.stringify(dataObj))
      if(res?.code==200){
        let obj = {}
        for(let i in res.data){
          res.data[i].nodeType = 'device';//定义当前节点的类型是摄像机还是通道
          res.data[i].parentId='0'
          res.data[i].name=res.data[i].deviceName
          res.data[i].children = res.data[i].deviceChannelList
          for(let x in res.data[i].children){
            res.data[i].children[x].nodeType = 'channel';//定义当前节点的类型是摄像机还是通道
            res.data[i].children[x].parentId = res.data[i].id
            res.data[i].children[x].name = res.data[i].children[x].channelName
            res.data[i].children[x].platformType = res.data[i].platformType
            res.data[i].children[x].equStatus = res.data[i].children[x].channelStatus
            if(this.treeId && this.treeId == res.data[i].children[x].id){
              obj = res.data[i].children[x]
            }
          }
        }
        vm.AlltreeData = res.data
        // console.log(vm.AlltreeData);
        if(vm.isFirstCurr){
          vm.$nextTick(()=>{
            vm.recurSionTree(vm.AlltreeData)
          })
        }else{
          vm.$nextTick(()=>{
            if(Object.keys(obj).length>0){
              vm.$refs.treeRef.setCurrentKey(obj.id)
              vm.$emit('currTree',obj)
            }
          })
        }
        
      }else{
        vm.$message.error(res.message)
      }
    },
    //递归
    async recurSionTree(data){
      for(var i=0;i<data.length;i++){
        if(data[i].nodeType == 'channel'){
          vm.$refs.treeRef.setCurrentKey(data[i].id)
          vm.$emit('currTree',data[i])
          return 
        }
        if(data[i].nodeType != 'channel' && data[i].children){
          await vm.recurSionTree(data[i].children)
          return
        }
      }
    },
    //过滤筛选
    filterNode (value, data) {
      if (!value) return true
      return data.name.includes(value)
    }
  }
}

</script>
<style lang='scss'>
.morePop{
  min-width: 96px !important;
  background: #313940 !important;
  color: #fff !important;
  padding: 0 !important;
  .el-popper__arrow{display: none;}
  .popper__arrow{
    border-right-color: #313940 !important;
    &::after{
      border-right-color: #313940 !important;
    }
  }
  .li{
    height: 24px;
    line-height: 24px;
    padding-left: 20px;
    position: relative;
    font-size: 12px;
    cursor: pointer;
    &.disabled{
      color: #999;
      &:hover{
        color: #999;
      }
    }
    .el-icon{
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
    }
    .child{
      display: none;
      position: absolute;
      left: 98%;
      top: 0;
      width: 96px;
      background: #313940;
      box-shadow: 0 2px 3px 0 rgba($color: #000000, $alpha: .5);
      border-radius: 4px;
      color: #fff;
    }
    &:hover{
      background: #2a2d31;
      color: #f0cd6e;
      .child{
        display:block;
      }
    }
  }
}
.deviceComp{
  height: 100%;
  box-sizing: border-box;
  .searchBD{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .toggleBtn{
      margin-left: 10px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .list{
    padding: 0 10px 10px;
    overflow: auto;
    height: calc(100vh - 423px);
    .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
      background-color:#409EFF;
      color:#fff;
    }
    .el-tree-node{
      user-select:none;
    }
  }
  .treeEditBtn{
    display: flex;
    height: 56px;
    border-top:1px solid #000;
    box-sizing: border-box;
    .item{
      height: 56px;
      padding-top: 8px;
      box-sizing: border-box;
      flex-grow: 1;
      text-align: center;
      cursor: pointer;
      .el-icon,.icon{
        font-size: 20px;
      }
      &:hover{
        color: #f2cf6f;
      }
      &.display{
        color: #999;
      }
    }
  }
  
}
</style>
