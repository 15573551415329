<template>
  <div class='monitoring mainContainer'>
    <rt-preview :isShowMap="true"></rt-preview>
  </div>
</template>
<script>
import rtPreview from '@/views/components/rtPreview.vue'
let vm;
export default {
  name:'monitoring',
  data() {
    return {
      
    }
  },
  components:{
    rtPreview,
  },
  created(){
    vm = this
  },
  methods:{

  }
}
</script>
<style lang='scss'>
.monitoring{
  background: #041738;
}
</style>